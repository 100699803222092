import React from "react";
import LayoutWithSidebar from "../layout/withSidebar";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import "../css/dashboard.css";

const ContactPage = () => {
  const pageTitle = "Dashboard";
  const pageSubtitle = "overview";

  return (
    <LayoutWithSidebar pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
      <Seo title="hessam's website, hi and welcome" />
      <div className="row gy-3">
        <div className="col-12 col-xl-8">
          <div className="card mb-3 profile-card">
            <div className="card-body">
              <div className="profile-cover">
                <StaticImage
                  className="profile-cover-self"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../images/cover.jpg"
                  quality={95}
                  alt="Profile cover"
                />
              </div>
              <div className="profile-desc">
                <div className="profile-avatar">
                  <StaticImage
                    className="profile-avatar-self"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/profile-pic-far.png"
                    layout="fixed"
                    width={128}
                    height={128}
                    quality={95}
                    alt="Profile picture"
                  />
                </div>
                <div className="profile-info">
                  <div className="profile-name">hessam khoobkar</div>
                  <div className="profile-role">
                    UI designer / Front-end Developer
                  </div>
                  <div className="profile-bio">
                    <span>
                      Hi, I'm your next UI compiler. Compiling your ideas to
                      designs &amp; designs to codes. I'm in love with building
                      components &amp; stacking them together, like playing with
                      legos. My focus is on web applications, platforms &amp;
                      web-based games, although I build multiple websites in
                      between, from news sites to e-commerces. I'm always happy
                      to help people around me &amp; learn new things in the
                      process too.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-xl-3">
              <div className="card report-card-line mb-3">
                <div className="card-body">
                  <div className="report-amount">5</div>
                  <div className="report-title">Daily tea intake</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-3">
              <div className="card report-card-line mb-3">
                <div className="card-body">
                  <div className="report-amount">40%</div>
                  <div className="report-title">Life under control</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-3">
              <div className="card report-card-line mb-3">
                <div className="card-body">
                  <div className="report-amount">3</div>
                  <div className="report-title">Open projects</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-3">
              <div className="card report-card-line mb-3">
                <div className="card-body">
                  <div className="report-amount">12 h</div>
                  <div className="report-title">Average reply time</div>
                </div>
              </div>
            </div>
          </div>

          <div className="card ">
            <div className="card-body">
              <div className="timeline-list">
                <div className="timeline-event">
                  <div className="event-title">
                    <span>UI designer &amp; Front-end developer</span>
                  </div>
                  <div className="event-client">
                    <span>
                      Self-employed Half-time &amp; Employed at (NDA) Half-time
                    </span>
                  </div>
                  <div className="event-date">
                    <span>June 2020 – Present</span>
                  </div>
                </div>
                <div className="timeline-event">
                  <div className="event-title">
                    <span>UI designer &amp; Front-end developer</span>
                  </div>
                  <div className="event-client">
                    <span>Self-employed Full-time</span>
                  </div>
                  <div className="event-date">
                    <span>Nov 2018 – June 2020</span>
                  </div>
                </div>
                <div className="timeline-event">
                  <div className="event-title">
                    <span>UI/UX designer &amp; Front-end developer</span>
                  </div>
                  <div className="event-client">
                    <span>NameShabake Gostar Kayer Full-time</span>
                  </div>
                  <div className="event-date">
                    <span>Aug 2017 – Sep 2018</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-4">
          <div className="card mb-3">
            <div className="card-body">
              <div className="info-list">
                <div className="info-item">
                  <div className="info-item-title">Birthday</div>
                  <div className="info-item-value">July 1994</div>
                </div>
                <div className="info-item">
                  <div className="info-item-title">Joined dev life</div>
                  <div className="info-item-value">Aug 2017</div>
                </div>
                <div className="info-item">
                  <div className="info-item-title">Projects</div>
                  <div className="info-item-value">21</div>
                </div>
                <div className="info-item">
                  <div className="info-item-title">Clients</div>
                  <div className="info-item-value">5</div>
                </div>
                <div className="info-item">
                  <div className="info-item-title">Current location</div>
                  <div className="info-item-value">Istanbul, Turkey</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <div className="info-title">
                <span>I convers in ...</span>
              </div>
              <div className="skill-list">
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/iran.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="Persian flag"
                  />
                  <span>Persian</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/turkey.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="Turkish flag"
                  />
                  <span>Turkish</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/great-britain.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="English flag"
                  />
                  <span>English</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <div className="info-title">
                <span>I design with ...</span>
              </div>
              <div className="skill-list">
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/figma.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="Figma logo"
                  />
                  <span>Figma</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/skype.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="Skype logo"
                  />
                  <span>Skype</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/google-docs.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="Google Docs logo"
                  />
                  <span>Google Docs</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <div className="info-title">
                <span>I code with ...</span>
              </div>
              <div className="skill-list">
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/react.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="React logo"
                  />
                  <span>React</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/vue.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="VUE logo"
                  />
                  <span>VUE</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/sass.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="sass logo"
                  />
                  <span>Sass</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/next.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="next logo"
                  />
                  <span>Next</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/gatsby.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="gatsby logo"
                  />
                  <span>Gatsby</span>
                </div>
                <div className="skill-item">
                  <StaticImage
                    className="skill-flag"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/tools/nuxt.png"
                    width={48}
                    height={48}
                    quality={95}
                    alt="nuxt logo"
                  />
                  <span>Nuxt</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default ContactPage;
